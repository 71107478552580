<template>
  <main id="content" role="main" class="overflow-hidden">

    <!-- ========== Hero Section ========== -->
    <div class="gradient-y-overlay-lg-white position-relative z-index-2 bg-img-hero overflow-hidden" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/images/apidays-banner.jpg') + ')'}">
      <!-- Breadcrumb -->
      <div class="container space-top-2 mt-md-6 pl-0">
        <div class="row justify-content-sm-between mb-5">
          <div class="col-md">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb mb-md-3 mb-0">
                <li class="breadcrumb-item">
                  <!-- <a href="/events"> -->
                  <router-link :to="{ path: '/events' }">
                    Events
                  </router-link>
                  <!-- </a> -->
                </li>
                <li class="breadcrumb-item active" aria-current="page">Apidays Live Hong Kong 2020</li>
              </ol>
            </nav>
          </div>

          <div class="col-md-auto text-md-right mt-2 ml-3 justify-content-end">
            <!-- <a class="btn btn-soft-primary btn-xs" href="/events/apidays-live-hongkong-tc"> -->
            <router-link class="btn btn-soft-primary btn-xs" :to="{ path: '/events/apidays-live-hongkong-tc' }">
              切換繁體中文 <i class="fas fa-exchange-alt" />
            </router-link>
            <!-- </a> -->
          </div>
        </div>
      </div>
      <!-- End Breadcrumb -->

      <div class="container space-bottom-2 position-relative">
        <div class="w-100 w-md-75 w-lg-60 mx-auto text-center">
          <span class="d-block small font-weight-bold text-cap mb-2">October 8, 2020</span>
          <h1>The Biggest API First Virtual Conference in Hong Kong</h1>
          <h3>The Open API Economy: Finance-as-a-Service &amp; API Ecosystems</h3>
          <img class="img-fluid mt-5" src="@/assets/img/events/apidays-live-hongkong/organisers/apidays-live-hk-color.png" style="max-width:15rem">
        </div>
        <div class="w-lg-85 mx-lg-auto pt-9">
          <div class="card overflow-hidden p-5">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left z-index-2">
              <div class="col-md-8 col-lg-9 mb-3 mb-md-0 pl-lg-5">
                <h3 class="mb-0">Apidays 2020 playback videos are now available!</h3>
              </div>
              <div class="col-md-4 col-lg-3 text-md-right">
                <a id="ga-event-apidays-2020-watch-now-cta-button" class="btn btn-primary transition-3d-hover" href="javascript:;" data-toggle="modal" data-target="#oahResourcesModal">Watch Now</a>
              </div>
            </div>
            <figure class="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
              <img class="img-fluid" src="@/assets/svg/components/half-circle-2-light.svg">
            </figure>
          </div>
        </div>
        <!-- <div class="w-lg-85 mx-lg-auto pt-9">
          <div class="card overflow-hidden p-5">
            <div class="row justify-content-md-start align-items-md-center text-center text-md-left z-index-2">
              <div class="col-md-8 col-lg-9 mb-3 mb-md-0 pl-lg-5">
                <h4 class="mb-0">Complete the Event Survey to Access 2020 On-Demand Video Highlights</h4>
              </div>

              <div class="col-md-4 col-lg-3 text-md-right">
                <a id="ga-event-apidays-2020-survey-button" class="btn btn-primary transition-3d-hover" href="https://bit.ly/apidaysHK2020-PostEvent" target="_blank">Start Survey</a>
              </div>
            </div>

            <figure class="w-35 position-absolute top-0 left-0 mt-n11 ml-n11">
              <img class="img-fluid" src="@/assets/svg/components/half-circle-2-light.svg">
            </figure>
          </div>
        </div> -->
      </div>
    </div>
    <!-- ========== End Hero Section ========== -->

    <!-- ========== Organiser Section ========== -->
    <OrganiserBar :list="organiser_list" />
    <!-- ========== End Organiser Section ========== -->

    <!-- ========== Participants Section ========== -->
    <div class="gradient-y-sm-primary space-2">
      <div class="container">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Participants</span>
          <h2>In 2020, Over 1000+ Participants From</h2>
        </div>

        <div class="mt-5 text-center">
          <a
            class="js-fancybox media-viewer"
            data-hs-fancybox-options="{
                    &quot;speed&quot;: 700
                }"
            :data-src="require('@/assets/img/events/apidays-live-hongkong/images/apidays-HongKong-logowall.png')"
            data-caption="Apidays Live Hong Kong 2020 Participants"
            style="cursor: pointer;"
          >
            <img class="img-fluid rounded shadow" src="@/assets/img/events/apidays-live-hongkong/images/apidays-HongKong-logowall.png" alt="Apidays Live Hong Kong 2020 Participants Logo Wall" data-aos="fade-up">
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Participants Section ========== -->

    <!-- ========== About Section ========== -->
    <div class="container space-2 mt-9">
      <div class="row justify-content-lg-between">
        <div class="col-lg-5 order-lg-2 pl-lg-0" data-aos="fade-left">
          <div class="bg-img-hero h-100 min-h-380rem" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/images/apidays.jpg') + ')'}" />
        </div>

        <div class="col-lg-6 order-lg-1">
          <div class="pt-8 pt-lg-0">
            <div class="mb-5 mb-md-6">
              <span class="d-block small font-weight-bold text-cap mb-2">About the Event</span>
              <h2 class="mb-3">The Open API Economy - Finance-as-a-Service &amp; API Ecosystems</h2>
              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>The world has seen various open banking initiatives launched - Hong Kong is no exception. With the launch of an Open API Framework in 2019, there is a strong driving force to banks to publish APIs for different financial products and services aiming of improving the end to end customers’ experience and discover effective contextual financial scenarios.</p>
                </div>
              </div>

              <div class="media mb-3">
                <span class="icon icon-xs icon-soft-primary icon-circle mr-3">
                  <i class="fas fa-check" />
                </span>
                <div class="media-body">
                  <p>API Ecosystems across sectors are now building up from banking, insurance, payment, travel, retail and entertainment. Regulated enterprises are now working with innovative startups to form regulated yet effective business collaboration enabled by APIs. Apidays is coming to Hong Kong firstly in 2020 to bring the industry thought leaders to share their latest insights.</p>
                </div>
              </div>

              <div class="text-center">
                <h4>Share your feedback with us and get 2020 replay videos!</h4>
              </div>
            </div>

            <div class="text-center">
              <a id="ga-event-apidays-2020-feedback-button" class="btn btn-primary btn-wide transition-3d-hover mb-3 mb-sm-0 mx-2" href="https://bit.ly/apidaysHK2020-PostEvent" target="_blank">Share Feedback</a>
              <a id="ga-event-apidays-2020-globalsite-button" class="btn btn-outline-primary mb-3 mb-sm-0 mx-2" href="https://www.apidays.global/" target="_blank">Apidays Global Site</a>
            </div>

            <!-- <span class="d-flex justify-content-center">
              <a class="btn btn-outline-primary transition-3d-hover mr-4" href="https://www.apidays.global/hongkong/" target="_blank">
                Apidays Global Site
              </a>
              <a class="btn btn-primary transition-3d-hover" href="https://bit.ly/apidaysHK2020-PostEvent" target="_blank">
                Share Feedback
              </a>
            </span> -->

          </div>
        </div>
      </div>

      <!-- More features -->
      <div class="row mt-11">
        <div class="col-md-3 col-6 mb-3 mb-md-5">
          <div class="pr-lg-4">
            <span class="h2 text-primary">1000</span>
            <span class="h2 text-primary">+</span>
            <p>attendees all over the globe</p>
          </div>
        </div>

        <div class="col-md-3 col-6 mb-3 mb-md-5">
          <div class="pr-lg-4">
            <span class="h2 text-primary">600</span>
            <span class="h2 text-primary">+</span>
            <p>companies in different sectors</p>
          </div>
        </div>

        <div class="col-md-3 col-6">
          <div class="pr-lg-4">
            <span class="h2 text-primary">45</span>
            <p>sessions about various topics on Open API Economy</p>
          </div>
        </div>

        <div class="col-md-3 col-6">
          <div class="pr-lg-4">
            <span class="h2 text-primary">52</span>
            <p>speakers from different professional roles</p>
          </div>
        </div>
      </div>
      <!-- End More features -->
    </div>
    <!-- ========== End About Section ========== -->

    <!-- ========== Speakers Section ========== -->
    <div class="bg-light space-2">
      <div class="container">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5 mb-md-9">
          <span class="d-block small font-weight-bold text-cap mb-2">Our Speakers</span>
          <h2>Speaker Lineup</h2>
        </div>

        <!-- Filters -->
        <input v-for="list in speaker_list" :id="list.group" :key="list.group" v-model="selected_group" type="radio" name="categories" :value="list.group">

        <ol class="filters-btn" style="margin-left: 1rem; overflow: auto; white-space: nowrap; -webkit-overflow-scrolling: touch;">
          <li v-for="list in speaker_list" :key="list.group">
            <label class="btn btn-sm btn-soft-secondary btn-pill py-2 px-3 m-1" :class="{'selectedGroup': selected_group === list.group}" :for="list.group">{{ list.title }}</label>
          </li>
        </ol>
        <!-- End Filters -->

        <span v-for="list in speaker_list" :key="`${list.group}_speaker`">
          <ol v-if="list.group === selected_group" class="filters-container row">
            <!-- Speaker -->
            <li v-for="speaker in list.speaker" :key="speaker.name" class="col-12 col-sm-6 col-lg-3 px-2 mb-3 filters-item" :data-category="list.group">
              <a class="card h-100 transition-3d-hover" data-toggle="modal" :data-target="'#' + speaker.data_target">
                <div class="card-body">
                  <div class="avatar avatar-xl avatar-circle mb-4">
                    <img class="avatar-img" :src="require('@/assets/img/events/apidays-live-hongkong/speakers/' + speaker.image_url)">
                  </div>
                  <h3>{{ speaker.name }}</h3>
                  <p>{{ speaker.job_title }}</p>
                </div>
              </a>

              <!-- Modal -->
              <div :id="speaker.data_target" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="speaker.data_target + 'Title'" aria-hidden="true">
                <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content">
                    <!-- Header -->
                    <div class="modal-top-cover bg-primary text-center">
                      <figure class="position-absolute right-0 bottom-0 left-0">
                        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
                          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                        </svg>
                      </figure>

                      <div class="modal-close">
                        <button type="button" class="btn btn-icon btn-sm btn-ghost-light" data-dismiss="modal" aria-label="Close">
                          <svg width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                            <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                          </svg>
                        </button>
                      </div>

                      <figure class="w-100 position-absolute bottom-0 left-0">
                        <img class="img-fluid" src="@/assets/svg/components/isometric-lego.svg">
                      </figure>
                    </div>
                    <!-- End Header -->

                    <!-- Speaker Profile  -->
                    <div class="modal-top-cover-avatar">
                      <img class="avatar avatar-xl avatar-circle avatar-border-lg avatar-centered shadow-soft" :src="require('@/assets/img/events/apidays-live-hongkong/speakers/' + speaker.image_url)">
                    </div>
                    <div class="text-center m-3">
                      <h2 class="mb-0">{{ speaker.name }}</h2>
                      <p v-if="speaker.comapny" class="d-block font-weight-bold mb-0">{{ speaker.comapny }}</p>
                      <p>{{ speaker.position }}</p>
                      <a :href="require('@/assets/img/events/apidays-live-hongkong/speakers/' + speaker.media_card)" class="btn btn-xs btn-soft-secondary" download>
                        <i class="fas fa-download mr-1" /> Download Social Media Card
                      </a>
                    </div>
                    <!-- End Speaker Profile  -->

                    <!-- Body -->
                    <div class="modal-body pb-sm-5 px-sm-5">
                      <div v-if="speaker.about" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">about</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <p v-for="item in speaker.about" :key="item">
                            {{ item }}
                          </p>
                          <div v-if="speaker.about_special" v-html="speaker.about_special" />
                        </div>
                      </div>

                      <div v-if="speaker.topic_title" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">speaking topic</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <h3 class="mb-3">{{ speaker.topic_title }}</h3>
                          <span v-if="speaker.topic_description">
                            <p v-for="item in speaker.topic_description" :key="item">
                              {{ item }}
                            </p>
                          </span>
                          <div v-if="speaker.topic_special" v-html="speaker.topic_special" />
                        </div>
                      </div>

                      <div v-if="(speaker.linkedin || speaker.twitter)" class="row mb-6">
                        <div class="col-12 col-lg-3 mb-1">
                          <h5 class="text-cap" style="color:#999999">get connected</h5>
                        </div>
                        <div class="col-12 col-lg-9">
                          <ul class="list-inline mb-0">
                            <li v-if="speaker.linkedin" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://hk.linkedin.com/in/' + speaker.linkedin" target="_blank">
                                <i class="fab fa-linkedin" />
                              </a>
                            </li>
                            <li v-if="speaker.twitter" class="list-inline-item">
                              <a class="btn btn-sm btn-icon btn-soft-secondary rounded" :href="'https://twitter.com/' + speaker.twitter" target="_blank">
                                <i class="fab fa-twitter" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="text-center mt-9">
                        <button type="button" class="btn btn-wide btn-primary transition-3d-hover" data-dismiss="modal" aria-label="Close">Back to Speaker List</button>
                      </div>
                    </div>
                  <!-- End Body -->
                  </div>
                </div>
              </div>
            <!-- End Modal -->
            </li>
          <!-- End Speaker -->

          </ol>
        </span>
      </div>
    </div>
    <!-- ========== End Speakers Section ========== -->

    <!-- ========== Agenda Section ========== -->
    <div class="container space-2">
      <!-- Title -->
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
        <span class="d-block small font-weight-bold text-cap mb-2">Schedule Details</span>
        <h2>Apidays Live Hong Kong 2020 Agenda</h2>
      </div>
      <!-- End Title -->

      <!-- Nav -->
      <div class="text-center">
        <ul class="nav nav-segment nav-pills scrollbar-horizontal" role="tablist">
          <li class="nav-item">
            <a id="agenda1-tab" class="nav-link active" data-toggle="pill" href="#agenda1" role="tab" aria-controls="agenda1" aria-selected="true">October 8, 2020</a>
          </li>
        </ul>
      </div>
      <!-- End Nav -->

      <!-- Tab Content -->
      <div class="tab-content pr-lg-4" data-aos="fade-up">
        <div id="agenda1" class="tab-pane fade show active" role="tabpanel" aria-labelledby="agenda1-tab">
          <div class="mt-5 text-center">
            <a
              class="js-fancybox media-viewer"
              href="javascript:;"
              data-hs-fancybox-options="{
                      &quot;speed&quot;: 700
                  }"
              :data-src="require('@/assets/img/events/apidays-live-hongkong/images/apidays-live-hongkong-2020agenda.png')"
              data-caption="Apidays Live Hong Kong 2020 Agenda"
            >
              <img class="img-fluid rounded shadow" src="@/assets/img/events/apidays-live-hongkong/images/apidays-live-hongkong-2020agenda.png" alt="Apidays Live Hong Kong 2020 Agenda">
            </a>
          </div>
        </div>
      </div>
      <!-- End Tab Content -->
    </div>
    <!-- ========== End Agenda Section ========== -->

    <!-- ========== Download Media Kit Section ========== -->
    <div class="gradient-y-sm-primary">
      <div class="container space-2" data-aos="fade-up">
        <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-5">
          <span class="d-block small font-weight-bold text-cap mb-2">Resources</span>
          <h2>Media Kit</h2>
        </div>
        <div class="text-center">
          <a id="ga-event-apidays-2020-media-kit-button" href="/document/apidays-live-hongkong/apidays-live-hongkong-media-kit.zip" class="btn btn-primary transition-3d-hover" download>
            <i class="fas fa-download mr-1" /> Download Media Kit
          </a>
        </div>
      </div>
    </div>
    <!-- ========== End Download Media Kit Section ========== -->

    <!-- ========== Sponsors Section ========== -->
    <div class="container space-2">
      <div class="w-md-80 w-lg-60 text-center mx-md-auto mb-9">
        <span class="d-block small font-weight-bold text-cap mb-2">Selected Sponsors</span>
        <h2>Apidays Live Hong Kong 2020 is Sponsored by</h2>
      </div>

      <div v-for="list in sponsor_list" :key="list.type" class="mb-11">
        <div class="text-center">
          <span class="divider mb-2 lead font-weight-bold">{{ list.type }}</span>
        </div>
        <div class="row text-center" :class="list.css_class">
          <div v-for="sponsor in list.sponsor" :key="sponsor.image_url" class="col-12" :class="sponsor.css_class">
            <img class="img-fluid" :class="sponsor.image_css" :src="require('@/assets/img/events/apidays-live-hongkong/sponsors/' + sponsor.image_url)">
          </div>
        </div>
      </div>

    </div>
    <!-- ========== End sponsors Section ========== -->

    <!-- ========== Gallery Section ========== -->
    <div class="bg-img-hero-center space-2" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-about-hero.svg') + ')'}">
      <div class="position-relative">
        <div class="container space-1 position-relative z-index-2 ">
          <div class="w-md-80 w-lg-80 text-center mx-md-auto mb-9">
            <span class="d-block small font-weight-bold text-cap mb-2">Gallery</span>
            <h2>The Best Moments of Apidays Live Hong Kong 2020</h2>
          </div>
          <div id="fancyboxGallery1" class="row">
            <div v-for="item in gallery_list" :key="item.image_url" class="col-12 px-2 transition-3d-hover" :class="item.class">
              <a
                class="js-fancybox media-viewer"
                href="javascript:;"
                data-hs-fancybox-options="{
                            &quot;selector&quot;: &quot;#fancyboxGallery1 .js-fancybox&quot;,
                            &quot;speed&quot;: 700
                        }"
                :data-src="require('@/assets/img/events/apidays-live-hongkong/images/' + item.image_url)"
                data-caption="Apidays Live Hong Kong 2020"
              >
                <div class="img-fluid rounded h-250rem bg-img-hero shadow-soft" :style="{'background-image': 'url(' + require('@/assets/img/events/apidays-live-hongkong/images/' + item.image_url) + ')'}" data-aos="fade-up" :data-aos-delay="item.data_aos_delay" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========== End Gallery Section ========== -->

    <!-- ========== Popup Modals ========== -->
    <div id="oahResourcesModal" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="oahResourcesModalTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="oahResourcesModal" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <div class="text-center">
                <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
                <p>This resource is now available on <strong>OpenAPIHub</strong>—our one-stop API Community Platform.<br>Please log in to the platform, or register as an OpenAPIHub member for FREE to watch the resource now!</p>
                <a id="ga-BW-CC15-01-03" href="https://hub.openapihub.com/member/community-updates/20201008-Apidays-Live-Hong-Kong-2020/resources" target="_blank" class="btn btn-primary transition-3d-hover">Watch on OpenAPIHub</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div id="recapSubscribeForm" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="recapSubscribeFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="recapSubscribeForm" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <div class="text-center">
                <h4>Subscribe to our newsletter and watch playback videos NOW!</h4>
                <img class="img-fluid max-w-23rem mx-auto mb-3" src="@/assets/svg/illustrations/replay-video.svg">
              </div>
              <form ref="recapSubscribeForm" />
              <div v-if="isSubmitted" class="modal-submitted-content">
                <div class="w-lg-80 mx-auto text-center">
                  <hr>
                  <h5 class="my-5">You may find the playback videos and discover other resources of Apidays Live Hong Kong 2020 below:</h5>
                  <router-link class="card card-frame p-3" :to="{ path: '/events/apidays-live-hongkong/recap' }">
                    <div class="d-flex align-items-center">
                      <figure class="max-w-6rem w-100">
                        <img class="img-fluid" src="@/assets/svg/icons/icon-41.svg">
                      </figure>
                      <div class="pl-3 font-weight-bold">Watch Apidays 2020 Recap NOW!</div>
                      <div class="ml-auto"><i class="fas fa-chevron-right" /></div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- ========== End Popup Modals ========== -->

  </main>
</template>

<script>
const $ = require('jquery')
import OrganiserBar from '@/components/organiser-bar'
import HSGoTo from '../../../assets/vendor/hs-go-to/src/js/hs-go-to'
import '../../../assets/js/hs.core.js'
import '../../../assets/js/hs.fancybox.js'
import '../../../assets/vendor/fancybox/dist/jquery.fancybox.js'
import 'bootstrap'

export default {
  name: 'Apidays',
  components: {
    OrganiserBar
  },
  data() {
    return {
      isSubmitted: false,
      gallery_list: [
        {
          image_url: 'apidays-onsite-1.jpg',
          class: 'col-md-7 mb-3',
          data_aos_delay: 100
        },
        {
          image_url: 'apidays-onsite-2.jpg',
          class: 'col-md-5 mb-3',
          data_aos_delay: 0
        },
        {
          image_url: 'apidays-onsite-3.jpg',
          class: 'col-md-3 mb-3',
          data_aos_delay: 0
        },
        {
          image_url: 'apidays-ppt-2.png',
          class: 'col-md-5 mb-3',
          data_aos_delay: 100
        },
        {
          image_url: 'apidays-ppt-3.png',
          class: 'col-md-4',
          data_aos_delay: 200
        },
        {
          image_url: 'apidays-ppt-4.png',
          class: 'col-md-4 mb-3 mb-md-0',
          data_aos_delay: 100
        },
        {
          image_url: 'apidays-ppt-1.png',
          class: 'col-md-5 mb-3 mb-md-0',
          data_aos_delay: 200
        },
        {
          image_url: 'apidays-ppt-5.png',
          class: 'col-md-3',
          data_aos_delay: 300
        }
      ],
      organiser_list: {
        title: 'Apidays Live Hong Kong 2020',
        image_path: 'apidays-live-hongkong',
        css: 'space-lg-3 space-2',
        organiser: [
          {
            post: 'Global Partner',
            image_url: 'apidays.png',
            data_aos_delay: 0,
            css: ''
          },
          {
            post: 'Lead Organizer',
            image_url: 'benovelty.png',
            data_aos_delay: 150,
            css: 'column-divider-md column-divider-20deg px-md-3'
          }
        ]
      },
      selected_group: 'MainTrack',
      speaker_list: [
        {
          group: 'MainTrack',
          title: 'Main Track',
          speaker: [
            {
              data_target: 'richardModal',
              image_url: 'richard-pro.jpg',
              name: 'Richard Lord',
              job_title: 'Chief Information Officer, Commercial Banking – Asia Pacific at HSBC',
              comapny: 'HSBC',
              position: 'Chief Information Officer, Commercial Banking – Asia Pacific',
              media_card: 'richard-smc.jpg',
              about: [
                'Richard Lord is the Chief Information Officer, Asia Pacific for HSBC Commercial Bank. He has a 25 year+ career working with contemporary and new technologies to change business, reach customers and deliver new services and propositions. Previously, Richard held a global technology leadership role in a leading advertising holding company and was an entrepreneur for 17 years, founding and operating one of the very first web technology companies in Australia in the early 1990’s.',
                'In his current role, Richard leads a regional team focused on creating excellent new customer experiences for commercial banking, leveraging new technologies and digital channels. Security, customer confidentially and privacy are paramount.'
              ],
              topic_title: 'Connected Future of Business',
              topic_description: [
                'Business is becoming increasingly connected as networks, APIs and technology allow integration between organisations. Hear about how HSBC is supporting businesses in Hong Kong to adapt to the Connected Future of Business.'
              ],
              linkedin: 'richardalord',
              twitter: 'richardlord'
            },
            {
              data_target: 'simonModal',
              image_url: 'simon-pro.jpg',
              name: 'Simon Redfern',
              job_title: 'Founder and CEO of Open Bank Project',
              comapny: 'Open Bank Project',
              position: 'Founder and CEO',
              media_card: 'simon-smc.jpg',
              about: [
                'Pioneering Open Banking since February 2010, Simon is the CEO of TESOBE and founder of the Open Bank Project, the open source API platform for banks. Simon studied electronic engineering in Manchester in the early \'80s where he focused on real-time programming and digital systems - and he subsequently worked as a software developer, architect, sysadmin, and DBA on numerous web / mobile platforms both large and small. A hacker at heart, Simon is also a composer and enjoys interdisciplinary creative collaborations.',
                'Simon is a keen supporter of open source and open data believing they are a sustainable force for social good.'
              ],
              topic_title: 'Open Banking, Yin and Yang in Hong Kong',
              topic_description: [
                'Moves, stances, and flows that might help banks on their Open Banking journey.'
              ],
              linkedin: 'simon-redfern-78a43b1',
              twitter: 'simsysims'
            },
            {
              data_target: 'gregModal',
              image_url: 'greg-pro.jpg',
              name: 'Greg Au-Yeung',
              job_title: 'Senior Advisor at Shanghai Fudan University',
              comapny: 'Shanghai Fudan University',
              position: 'Senior Advisor',
              media_card: 'greg-smc.jpg',
              about: [
                'Greg has held senior executive positions at different global banks, including Saxo, UBS, ANZ, Morgan Stanley and State Street Bank.  He has a solid track record pioneering, building, and managing technology centres in China that deliver innovation solutions and support digital transformation program.  During his times working in the mainland, Greg has also been involved in establishing a Microcredit/P2P (formerly AIG) start-up and consultancy business, respectively.  Prior to China, he has worked in US, Japan, and Hong Kong.'
              ],
              topic_title: 'Going API First in Financial Services within Greater China',
              topic_description: [
                'An overview of API development status within the financial industry across Greater China region.'
              ],
              linkedin: 'gregauyeung'
            },
            {
              data_target: 'helalModal',
              image_url: 'helal-pro.jpg',
              name: 'Helal Nouri',
              job_title: 'Senior API Consultant at Rabobank',
              comapny: 'Rabobank',
              position: 'Senior API Consultant',
              media_card: 'helal-smc.jpg',
              about: [
                'Helal Nouri is a Product Owner and Consultant and has been working with API Management in Banking, Aviation and HR & Payroll industry. Currently he consults and on-boards API providers and consumers on to the enterprise Management Portal at Rabobank. Prior to this role, Helal held posts as a Product Owner API, API Software Designer, Automation Engineer and Strategy- and Application Consultant at several companies. Helal holds a Bachelor of Science in Business Economics from Utrecht University and a Master’s degree in Social Sciences from the Pompeu Fabra University of Barcelona.'
              ],
              topic_title: 'Embark on a journey of API Management Maturity',
              topic_description: [
                'The challenges that enterprises face who have just started developing API\'s are different from those that have already established a living, breathing API Ecosystem. Yet both must focus on first creating and then maintaining an API culture. How do you communicate best practices around design, versioning, standardisation and lifecycle policies to a wide audience within your company? And how do you convince business owners and management of adopting API tools and platforms that allow fast proliferation of API\'s? In this talk, I would like to elaborate on the challenges I have seen companies in different industries face and solutions that were adopted. The latter ranging from establishing comprehensive API Platforms, to API communities that include guilds and advocates.'
              ],
              linkedin: 'helalnouri'
            },
            {
              data_target: 'jedModal',
              image_url: 'jed-pro.jpg',
              name: 'Jed Ng',
              job_title: 'Tech & API Investor',
              position: 'Tech & API Investor',
              media_card: 'jed-smc.jpg',
              about: [
                'Jed is a tech investor and operator. In last 2 years, he built the world\'s largest API Marketplace in partnership with A16Z-backed RapidAPI.',
                'He is an active investor and in B2Dev, SaaS, deep tech, API startups. He is also an Advisor to Turing, Alectio, and Voxeet (exit).'
              ],
              topic_title: 'The Business of APIs',
              topic_description: [
                'This presentation titles \'The Business of APIs\' will share insights gained from building the world\'s largest API Marketplace. We\'ll share lessons on why API business models are intrinsically valuable, API-as-a-Product thinking and best practices on API Marketing.'
              ],
              linkedin: 'jedng',
              twitter: 'jed_ng1?lang=en'
            },
            {
              data_target: 'alanModal',
              image_url: 'alan-pro.jpg',
              name: 'Alan Glickenhouse',
              job_title: 'Digital Transformation and API Business Strategist at IBM',
              comapny: 'IBM',
              position: 'Digital Transformation and API Business Strategist',
              media_card: 'alan-smc.jpg',
              about: [
                'Alan Glickenhouse is the IBM Digital Transformation and API Business Strategist. Alan assists clients with their business and IT strategy for Digital Transformation and the API Economy. Starting with an understanding of the client’s business direction, IT strategy, and existing environment (both business and technical), Alan helps businesses successfully adopt a Digital Transformation and API strategy that fits their environment. He meets clients in all industries, all geographies, and of all sizes and brings knowledge of best practices shared with and by these businesses. Alan is the author of over 150 papers, articles and videos.'
              ],
              topic_title: 'State of the API Economy',
              topic_description: [
                'Where do we stand with APIs?  In this session we look at the Macro trends that are driving the use of APIs and some of the challenges that companies are still struggling with in their API initiatives.  Then we will look forward to where APIs are taking us in the near term and perhaps a little further in the future as well.'
              ],
              linkedin: 'alan-glickenhouse-2b065a3',
              twitter: 'ARGlick'
            },
            {
              data_target: 'barryModal',
              image_url: 'barry-pro.jpg',
              name: 'Barry Chan',
              job_title: 'Partner, Financial Services Sector of IBM',
              comapny: 'IBM',
              position: 'Partner, Financial Services Sector',
              media_card: 'barry-smc.jpg',
              about: [
                'Mr. Barry Chan has 20+ years of experience in consultation and information technology in Hong Kong. He expertises in leading digital transformation strategy formulation incorporating innovations in open banking, AI and blockchain etc. Barry has strong proven industry track record leading complex systems projects, bringing sustainable business value to banks and financial institutes.'
              ],
              about_special: '<span class="d-block font-weight-bold" >Projects Profile:</span><ul class="mb-3"><li>Open Banking API Ecosystem</li><li>Digital transformation with microservices architecture</li><li>Core banking systems integration</li><li>AI conversational banking</li></ul><span class="d-block font-weight-bold" >Qualifications:</span><ul class="mb-3"><li><span>Master of Management in Information Technology</span><span class="d-block text-body font-size-1">Macquarie Graduate School of Management, Australia</span></li><li><span>B. Sc. Engineering in Industrial Engineering</span><span class="d-block text-body font-size-1">The University of Hong Kong</span></li></ul>',
              topic_title: 'Digital Banking Survey in HK and How it Relates to Open Banking',
              topic_description: [
                'The presentation will share a summary of Digital Banking HK Survey conducted mid 2020 and highlight the open banking API opportunities to grow business in Greater Bay Area (GBA).'
              ],
              linkedin: 'barry-chan-061a0518'
            },
            {
              data_target: 'alexModal',
              image_url: 'alex-pro.jpg',
              name: 'Alex Chan',
              job_title: 'CEO of FORMS HK',
              comapny: 'FORMS HK',
              position: 'CEO',
              media_card: 'alex-smc.jpg',
              about: [
                'Currently CEO of FORMS Syntron Information (HK) Limited, under SZ FORMS Syntron Information Co. Ltd ("FORMS" in short) which is listed in SZ with stock code 300468.',
                'Under Alex’s leadership, FORMS is now the leading digital technology advisor and enabler to help virtual banks to build the bank, and established banks to change the bank.',
                'Alex is also advising and supporting a number of visionary FinTech startups which make up the portfolio of FORMS GBA FinTech Accelerator Program.',
                'Before joining FORMS HK, Alex was in charge of IBM HK’s banking industry and the leader in driving FinTech development.',
                'More than 20 years of solid consulting and business development experience with major banks in HK. Have broad and deep experience about banks\' strategy, business, operations and IT. Trusted by a lot of senior bankers which resulted in a long list of successful transformational and mega projects among banks.',
                'Impressive track records in bringing up a team of sharp and outstanding financial services industry consulting and sales leaders at IBM.',
                'Renowned for deep banking industry insights and formulating solutions to address specific banking needs.'
              ],
              topic_title: 'Beyond Open Banking - Open Innovation & Open Business',
              topic_description: [
                'Should Open Banking be an innovation initiative? Digital banking initiative? Or a totally new business model? Let’s strategize how to realize the maximum values out of this new banking paradigm.'
              ],
              linkedin: 'alex-chan-formshk'
            },
            {
              data_target: 'patrickModal',
              image_url: 'patrick-pro.jpg',
              name: 'Patrick C.S. Fan',
              job_title: 'CEO & Founder of beNovelty',
              comapny: 'beNovelty',
              position: 'CEO & Founder',
              media_card: 'patrick-smc.jpg',
              about: [
                'Patrick Fan is a serial entrepreneur and CEO of beNovelty Limited - an award-winning API first company. Patrick is a recognized thought leader in API space and the Hong Kong organizer & representatives of APIDays - the world\'s Leading API Conference Series. He is an active participant & speaker of major API Developer Communities in different regions. Patrick is current an entrepreneur-in-residency (EIR) of a venture capital and providing API advisory to various local startups & enterprise across sectors to adopt API first strategy. With his exceptional knowhow in Open API domain, Patrick is part of the Hong Kong API Startup Registration Scheme workgroup and is appointed by HKQAA as an associate trainer in API Cybersecurity & Information Security.'
              ],
              linkedin: 'patrickcsfan'
            },
            {
              data_target: 'julianModal',
              image_url: 'julian-pro.jpg',
              name: 'Julian Schillinger',
              job_title: 'Co-Founder and Co-CEO of Privé Technologies',
              comapny: 'Privé Technologies',
              position: 'Co-Founder and Co-CEO',
              media_card: 'julian-smc.jpg',
              about: [
                'Julian Schillinger is the Co-Founder and Co-CEO of Privé Technologies and is responsible for the technological infrastructure and business development of the organization. Julian is the key design and implementation engineer of an evolutionary algorithm program, which is a discipline of artificial intelligence, that powers the Privé Managers platform.',
                'Prior to founding Privé, he worked at J.P. Morgan Asia as a Vice President and the Head of the Index Structuring Team, where he led product development for quantitative indices and gained in-depth experience in product placement of alternative investment products. Prior to that, Julian was the founder and CEO of a company specializing in software development.',
                'Julian graduated from the University of Regensburg with a degree in Business Computer Science. He also serves as a Type 1 Responsible Officer at Privé. He is also the inventor of the patented vFunds architecture offered on the Privé Managers platform.'
              ],
              topic_title: 'The Future of Financial Institutions: Disintegration of the Value Chain Using API-driven Platforms',
              topic_description: [
                'Discussing what the future of banking and financial institutions looks like -- with the increasing platformification and API based delivery of specific services and products. There seem to emerge two options: become a platform or specialise and plug into one.'
              ],
              linkedin: 'julianschillinger'
            },
            {
              data_target: 'robModal',
              image_url: 'rob-pro.jpg',
              name: 'Rob Whiteley',
              job_title: 'Vice President of NGINX (Part of F5)',
              comapny: 'NGINX (Part of F5)',
              position: 'Vice President',
              media_card: 'rob-smc.jpg',
              about: [
                'Rob Whiteley is Vice President at NGINX (part of F5), the engine delivering sites and applications for the modern web.',
                'With close to 2 decades in IT, Rob has led marketing, product, and analyst teams for companies like Hedvig, Riverbed, and Forrester. Rob uses his experience working with enterprise IT and DevOps customers to deliver thought leadership and drive demand for modern IT infrastructure solutions.',
                'Rob has a BS degree in Computer Engineering from Tufts University.'
              ],
              topic_title: 'How API-as-a-Service can Improve CX, Scalability, and Security with an Internal API Cloud',
              topic_description: [
                'The digital environment moves quickly, your customers expect a compelling, reliable digital experience, and your partners expect an easy, frictionless integration with your services. Are your API management capabilities keeping pace? Today, companies need an agile, evolvable solution for managing APIs. Cutting edge companies are building out “API-as-a-Service”— a secure API management cloud, either private, public, or hybrid – to consume API capabilities efficiently and effectively. In this session, Rob Whiteley will delve into real-life experiences, and explore:'
              ],
              topic_special: '<ul><li>How the trends around API adoption relate to digital transformation</li><li>Benefits that build an internal API cloud platform delivers to cutting-edge companies</li><li>A high-level vision for how you can build such a platform</li><li>Everyday use cases and examples, including insight from one of the world’s largest credit card companies</li><li>Drawbacks of not building API-as-a-Service and how it can affect your business</li></ul>'
            },
            {
              data_target: 'johanModal',
              image_url: 'johan-pro.jpg',
              name: 'Johan Fantenberg',
              job_title: 'Principal Solution Architect at ForgeRock',
              comapny: 'ForgeRock',
              position: 'Principal Solution ArchitectPrincipal Solution Architect',
              media_card: 'johan-smc.jpg',
              about: [
                'Johan is an experienced technologist with a keen eye for emerging technologies and technology driven business opportunities. With more than 25 years of experience from the high tech industry he brings insight and innovation to ForgeRock, our customers and the digital identity market place. His career has seen him working with iconic companies such as Nokia, Ericsson and Sun Microsystems across industries such as financial services, telecommunications as well as in international standardisation.'
              ],
              topic_title: 'Digital Native\'s Needs & Wants from Financial Services Providers'
            },
            {
              data_target: 'garryModal',
              image_url: 'garry-pro.jpg',
              name: 'Garry Sien',
              job_title: 'Principal Advisory Consultant at Alibaba Cloud International',
              comapny: 'Alibaba Cloud International',
              position: 'Principal Advisory Consultant',
              media_card: 'garry-smc.jpg',
              about: [
                'Garry is the Regional Senior Solutions Architect for Fintech and Innovation at Alibaba Cloud and is an International Technologist with extensive experience in digital transformation for the Financial Services and Public Sector.',
                'Based in Hong Kong, Garry is responsible for deepening Alibaba Cloud’s focus on fintech and product  innovation, Ecosystem build-out and Go to market strategy for Hong Kong, Macau, Philippines, Taiwan and Korea. He is also serving as the co-chair of the Fintech Association of Hong Kong’s Cloud and Cybersecurity committee.',
                'Prior to this, Garry was the Head of Technology for ANZ China. He has spent the last decade working across the Asia Pacific region, delivering key initiatives in an array of roles across Business and Technology functions. These include business analysis, project and digital transformation, application and infrastructure support, end-user experience, and information security and risk assurance. These experiences have given Garry the opportunity to master his cultural adaptability by working with teams globally to enable digital transformation.',
                'Garry combines his passion for digital transformation and innovation, and key strengths in stakeholder management, business acumen, multi-lingual capability and cross boarder collaboration to help Alibaba Cloud better support the industry in their digital transformation journey.'
              ],
              topic_title: 'Fast Track the Open Banking Ecosystem with Platform Business Model',
              topic_special: '<ul><li>What are the business drives for the platform business model?</li><li>The technology is not new, what\'s changed?</li><li>What are the lessons learnt from around the region?</li></ul>'
            }
          ]
        },
        {
          group: 'ArchitectsTrack',
          title: 'Architects Track',
          speaker: [
            {
              data_target: 'shahnawazModal',
              image_url: 'shahnawaz-pro.jpg',
              name: 'Shahnawaz Backer',
              job_title: 'Principal Security Advisor at F5 Networks',
              comapny: 'F5 Networks',
              position: 'Principal Security Advisor',
              media_card: 'shahnawaz-smc.jpg',
              about: [
                'Shahnawaz Backer does security analysis and threat research at F5. He has been a developer, systems engineer, solutions architect, and security advisor, and he firmly believes that cyber security is a collective responsibility. In his current role at F5 labs, his research focuses on digital identity, fraud vectors, bots, and modern application architectures. He writes on IT security at f5labs.com and has co-authored a Redbook on access management deployment patterns. His works pivots on boosting security with visibility, intelligence, and automation.'
              ],
              topic_title: 'The Art and Science of Rate Limits',
              topic_description: [
                'As open APIs gain traction, there is a growing need to check and enforce limits on users and computers coming in. The talks discusses the usage of IP and tokens to enforce limits on API\'s deployed in mini or micro service format'
              ],
              linkedin: 'backers',
              twitter: 'sbacker27'
            },
            {
              data_target: 'hugoModal',
              image_url: 'hugo-pro.jpg',
              name: 'Hugo Guerrero',
              job_title: 'APIs & Messaging Developer Advocate at Red Hat',
              comapny: 'Red Hat',
              position: 'APIs & Messaging Developer Advocate',
              media_card: 'hugo-smc.jpg',
              about: [
                'Hugo Guerrero works at Red Hat as an APIs and messaging developer advocate. In this role, he helps the marketing team with technical overview and support to create, edit, and curate product content shared with the community through webinars, conferences, and other activities. With more than 20 years of experience as a developer, consultant, architect, and software development manager, he also works on open source software with major private and federal public sector clients in Latin America.'
              ],
              topic_title: 'Data Gateways: Building “Data-as-a-Service” for the Hybrid Cloud',
              topic_description: [
                'Application architecture evolution has fragmented the backend implementation into independent microservices and functions. However there is still a gap on the way this evolution has dealt with data as it tends to avoid dealing with static environments. At the same time, microservices encourage developers to create new polyglot data persistence layers that then, need to be composite to deliver business value. How can we apply the knowledge from API gateways to these new data stores?',
                'In this session we will talk about:'
              ],
              topic_special: '<ul><li>How data gateways act like API gateways to offer a secured abstraction layer on top of the physical data stores,</li><li>the different data gateway types and architectures, and</li><li>extended data-proxy for hybrid cloud deployments</li></ul>',
              linkedin: 'hugoguerrero',
              twitter: 'hguerreroo'
            },
            {
              data_target: 'marcoModal',
              image_url: 'marco-pro.jpg',
              name: 'Marco Palladino',
              job_title: 'CTO and Co-Founder of Kong Inc.',
              comapny: 'Kong Inc.',
              position: 'CTO and Co-Founder',
              media_card: 'marco-smc.jpg',
              about: [
                'Marco Palladino is an inventor, software developer and internet entrepreneur based in San Francisco, California. He is the co-founder and CTO of Kong, the most widely adopted open source API platform. Besides being a core maintainer, Marco is currently responsible for the design and delivery of the Kong products, while also providing the technical thought leadership around APIs and microservices within Kong and the external community. Marco was also the co-founder of Mashape, which started in 2010 and is today the largest API marketplace in the world.'
              ],
              topic_title: 'Connectivity Rules Everything Around Us',
              topic_description: [
                'Service mesh is the future of application connectivity – it increases the security, reliability and observability of application traffic in any architecture. However, understanding and deploying service meshes in production is a daunting undertaking. In this session, Marco Palladino will explore service mesh, from understanding the basic concepts of service mesh to discussing  how to implement it from a practical standpoint, including:'
              ],
              topic_special: '<ul><li>Why service mesh has emerged as a solution</li><li>Service mesh 101: concepts, benefits and pitfalls</li><li>How to leverage service mesh to build reliable modern applications</li><li>Deploying a service mesh and what to watch out for</li></ul>',
              linkedin: 'marcopalladino',
              twitter: 'subnetmarco'
            },
            {
              data_target: 'asankaModal',
              image_url: 'asanka-pro.jpg',
              name: 'Asanka Abeysinghe',
              job_title: 'Chief Technology Evangelist at WSO2, Inc',
              comapny: 'WSO2, Inc',
              position: 'Chief Technology Evangelist',
              media_card: 'asanka-smc.jpg',
              about: [
                'Asanka’s goal is to connect humans and technology by helping organizations implement digital transformation programs that result in consumer-driven digital applications. In his current role, Asanka drives efforts to create, refine, and enhance WSO2’s corporate reference architecture and is responsible for spearheading a thought leadership outlook that defines WSO2’s corporate reference methodology for development, customer success, and implementation. Working closely with customers, partners, and analysts, he evangelizes WSO2’s technology vision. Asanka has over 20 years of industry experience, which includes designing and implementing highly scalable distributed systems, SOA and microservice architectures in the financial domain, mobile platforms, and various business integration solutions. He is also a committer of the Apache Software Foundation. Asanka is a regular speaker at numerous events, including ApacheCon, QCon, O’Reilly-*,  API Days, API Strategy, LinuxFoundation-*,  Gartner-*, WSO2Con, and many tech meetups in San Francisco Bay Area.'
              ],
              topic_title: 'Achieving True Agility Through Programmability',
              topic_description: [
                'Enterprise agility—the ability to adapt fast and seize new opportunities while reducing costs—is critical for an organization to survive and thrive in this evolving digital age. While the rewards of bringing about agile transformation are significant, how many companies really achieve key results? Did you know the success rate is less than 10%? Even organizations that follow various agile methodologies do not adhere to them across the board, resulting in what’s known as agile-waterfall or wagile approaches. Is there a pragmatic way to become truly agile?',
                'Yes, by embracing programmability. This approach goes beyond infrastructure automation, which is a common practice, by enabling programmatic access to automate actions, procedures, processes, and runtimes used in an application’s entire lifecycle. With programmability and end-to-end automation, teams can increase productivity and flexibility and quickly decommission repeatable tasks; they can also spend more time on implementing innovative ideas.',
                'Asanka will share his experience from applying programmability in successful projects delivered iteratively, rapidly and in a timely way. In addition to the associated lean methodology, he will discuss a supportive reference architecture.'
              ],
              linkedin: 'asankaabeysinghe'
            },
            {
              data_target: 'donnieModal',
              image_url: 'donnie-pro.jpg',
              name: 'Donnie Prakoso',
              job_title: 'Senior Developer Advocate at Amazon Web Services (AWS)',
              comapny: 'Amazon Web Services (AWS)',
              position: 'Senior Developer Advocate',
              media_card: 'donnie-smc.jpg',
              about: [
                'Donnie Prakoso is a software engineer, self-proclaimed barista, café racer enthusiast, and Senior Developer Advocate at AWS. With more than 16 years of experience in the technology industry, from telecommunications, banking to startups. He is now focusing on helping the developers to understand varieties of technology to transform their ideas into execution. He loves coffee and any discussion of any topics from microservices to AI/ML.'
              ],
              topic_title: 'Orchestrating Workflow for Microservices Integration',
              topic_description: [
                'Workflows are made up of a series of steps, with the output of one step acting as input into the next. AWS Step Functions lets you coordinate multiple AWS services into serverless workflows so you can build and update apps quickly. Furthermore, with Step Functions, you can design and run workflows that stitch together services into feature-rich applications. Join us in this session to understand how to get started with AWS Step Functions and how to integrate with microservices.'
              ],
              linkedin: 'donnieprakoso',
              twitter: 'donnieprakoso'
            },
            {
              data_target: 'zeevModal',
              image_url: 'zeev-pro.jpg',
              name: 'Zeev Avidan',
              job_title: 'Chief Product Officer at OpenLegacy',
              comapny: 'OpenLegacy',
              position: 'Chief Product Officer',
              media_card: 'zeev-smc.jpg',
              about: [
                'As Chief Product Officer, Zeev defines the roadmap of OpenLegacy’s Microservice-based API Integration & Management Software, aligns software features to the market and brings the software to the market. Zeev ensures that OpenLegacy product delivers the right features to meet customers’ growing needs. During his more than 15 years of experience, Zeev has held leadership positions, delivering information technology solutions within enterprise IT departments and in companies that provide consulting services– most recently at the leading credit card company Isracard and Hilan Tech. He also served as a senior consultant in the Israel Defense Force, dealing with advanced computing systems.'
              ],
              topic_title: 'The Future of Legacy - How to Leverage Legacy and On-prem Assets in Your Digital Transformation with Digital-Driven Integration',
              topic_description: [
                'Thriving in an ever-changing digital world means more than solving today\'s challenges. For most organizations, legacy systems are the slowest link in the continuous delivery chain, but microservices and APIs are bringing legacy systems up-to-speed. Rather than the costly and daunting task of legacy migration or modernization, some companies have found a different approach. This talk uses real-life examples explaining how microservice APIs and digital-driven integration approach are used to deliver digital transformation today, while laying a path toward future legacy migration.'
              ],
              linkedin: 'zeev-avidan'
            },
            {
              data_target: 'davidModal',
              image_url: 'david-pro.jpg',
              name: 'David Stewart',
              job_title: 'CEO of Approov',
              comapny: 'Approov',
              position: 'CEO',
              media_card: 'david-smc.jpg',
              about: [
                '30+ years experience in software security, mobile apps/APIs, embedded software tools, design services, chip design, design automation tools, technical support, marketing, sales, fundraising, executive management & board advisory roles.',
                'Current focus is growing a business delivering revenue assurance for enterprises reliant on mobile channels to reach their customers. Approov is a SaaS security solution preventing APIs being accessed by anything other than genuine instances of your mobile apps running in a safe environment.'
              ],
              topic_title: 'API Abuse - Comprehension and Prevention',
              topic_description: [
                'As APIs have become pervasive in digital platforms, attitudes to security have become more serious. However, there is still much work to do.',
                'This presentation will explore the topic of API abuse: what is it, why do attackers do it to your business, and how should you deal with it?',
                'Real life examples from companies in retail, fintech and transportation will be used to illustrate sensible, light touch and effective ways to ensure your APIs can identify and reject traffic which intends to disrupt your revenue streams.'
              ],
              linkedin: 'david-stewart-8367b',
              twitter: 'approov_io'
            },
            {
              data_target: 'stanleyModal',
              image_url: 'stanley-pro.jpg',
              name: 'Stanley Chou',
              job_title: 'Head of Cybersecurity at OneDegree',
              comapny: 'OneDegree',
              position: 'Head of Cybersecurity',
              media_card: 'stanley-smc.jpg',
              about: [
                'Stanley is the Head of Cybersecurity at OneDegree (https://www.onedegree.global), an InsurTech company providing a digital solutions for insurers and insurance providers. Stanley’s role in OneDegree is to layout company\'s cyber strategy and protect the company against cyber threats. Prior to joining OneDegree, he worked in Citibank and Deloitte for 12 years where he held various information security & information technology governance roles.'
              ],
              about_special: '<span class="d-block font-weight-bold">Certifications:</span><ul><li>Certified Information Systems Security Professional (CISSP)</li><li>Certified Cloud Security Professional (CCSP)</li><li>Certified Information Security Auditor (CISA)</li></ul>',
              topic_title: 'Fuzzing API to Identify API1: Broken Object Level Authorization Control and API5: Broken Function Level Authorization Control',
              topic_description: [
                'APIs create a wide attack surface of access control because of the exposure of endpoints. By manipulating payloads, attackers can exploit vulnerable endpoints to break authorization control. This is why broken object level authorization control and broken function level control are ranked no.1 and no.5 of OWASP API security. In this session, we will introduce these notorious API vulnerabilities and how to identify them by fuzzing the API endpoints.'
              ],
              linkedin: 'stanley-c-bb47434b'
            },
            {
              data_target: 'benModal',
              image_url: 'ben-pro.jpg',
              name: 'Ben Dechrai',
              job_title: 'Developer Advocate at Auth0',
              comapny: 'Auth0',
              position: 'Developer Advocate',
              media_card: 'ben-smc.jpg',
              about: [
                'Experienced technologist with a demonstrated history of team leadership, business development, and community engagement. Skilled and confident communicator with pre-sales and conference speaking experience. Strong professional with a background in software engineering, solutions architecture, and business analysis.'
              ],
              topic_title: 'Authorised is Not a Yes/No Question',
              topic_description: [
                'Whether you just want to identify site administrators, or need more fine-grained control, authentication and authorisation are critical aspects to almost all software.',
                'This talk includes a demo on implementing Role-Based Access Control (RBAC), and will help you understand the pros and cons compared to Attribute-Based Access Control (ABAC).'
              ],
              linkedin: 'bendechrai'
            },
            {
              data_target: 'hieuModal',
              image_url: 'hieu-pro.jpg',
              name: 'Hieu Nguyen Nhu',
              job_title: 'Microsoft Asia Pacific Cloud Native Technical Lead at Microsoft',
              comapny: 'Microsoft',
              position: 'Microsoft Asia Pacific Cloud Native Technical Lead',
              media_card: 'hieu-smc.jpg',
              about: [
                'He is an experienced Mobile & Backend Developer with a demonstrated history of working in the computer software industry.',
                'Hieu is currently focusing on helping Microsoft enterprise customers to successfully deliver their Cloud Native Applications on Azure in Asia.'
              ],
              topic_title: 'Orchestrating APIs at Scale',
              topic_description: [
                'Walkthrough session to orchestrate Serverless, Container and integration APIs using Azure APIM.'
              ]
            }
          ]
        },
        {
          group: 'DevelopersTrack',
          title: 'Developers Track',
          speaker: [
            {
              data_target: 'jenksModal',
              image_url: 'jenks-pro.jpg',
              name: 'Jenks Guo',
              job_title: 'Developer Evangelist at Xero',
              comapny: 'Xero',
              position: 'Developer Evangelist',
              media_card: 'jenks-smc.jpg',
              about: [
                'Jenks Guo is a developer advocate at Xero with years of experience in building web applications & API integration. He is passionate about educating developers about new full-stack and cloud technology to build online applications that solves complex problems. He loves tinkering electronics and contributing to open source software projects. On Twitter @jenksguo'
              ],
              topic_title: 'Building an API Client SDK',
              topic_description: [
                'The talk will help tech leaders of platform businesses understand the how to level up their developer experience by improving how they build client SDKs from OpenAPI specifications.'
              ],
              linkedin: 'jenks-guo-58257438',
              twitter: 'JenksGuo'
            },
            {
              data_target: 'philModal',
              image_url: 'phil-pro.jpg',
              name: 'Phil Nash',
              job_title: 'Developer Evangelist at Twilio',
              comapny: 'Twilio',
              position: 'Developer Evangelist',
              media_card: 'phil-smc.jpg',
              about: [
                'Phil is a developer evangelist for Twilio and a Google Developer Expert living in Melbourne, Australia. He loves working with Ruby or JavaScript to build web applications and tools to help developers. He once helped build a website that captured the world\'s favourite sandwich fillings. He has too many GitHub repositories and you can find him working on some of them live on Twitch.',
                'Away from the keyboard, Phil listens to ska punk, enjoys discovering new beers and runs the occasional half marathon.',
                'Phil tweets at @philnash and you can find him elsewhere online at https://philna.sh.'
              ],
              topic_title: 'The Trouble with Webhooks',
              topic_description: [
                'Webhooks are a core part of the way developers interact with the Twilio API but can be the hardest to work with when learning the platform. Phil will share the many ways the Twilio team has tried to improve the experience, from documentation, tooling and serverless functions, all the way to building and releasing a video game.'
              ],
              linkedin: 'philnash',
              twitter: 'philnash'
            },
            {
              data_target: 'danieleModal',
              image_url: 'daniele-pro.jpg',
              name: 'Daniele Bernardi',
              job_title: 'Staff Developer Relations at Twitter',
              comapny: 'Twitter',
              position: 'Staff Developer Relations',
              media_card: 'daniele-smc.jpg',
              about: [
                'Daniele is part of the Developer Relations team at Twitter, helping developers build better apps. Daniele’s passion and job is to inspire developers all over the world to create the best Twitter API integrations that can in turn raise the bar for the entire ecosystem.'
              ],
              topic_title: 'Health oriented API design for the public conversation',
              topic_description: [
                '2020 has proven to be a pivotal year for data privacy, harassment and fake news.',
                'Despite huge investments in AI, social media platforms still struggle to deal with abusive and irrelevant content. Developers, once key to the success of any API platform, feel alienated and helpless – they want to contribute, but they don\'t have the right API to do so. Why is this happening, and how can platforms help the broader developer ecosystem build a better internet?',
                'Platforms will have no choice but to change their API strategy so that developers can help people be safe again. Join this talk to learn how API platforms are being shaped to give developers the tool they need to enable healthier conversations globally and at scale, and how many API can already be used to enable new viable pathways for detection of abusive or unauthentic content.'
              ],
              linkedin: 'iamdaniele',
              twitter: 'i_am_daniele'
            },
            {
              data_target: 'franModal',
              image_url: 'fran-pro.jpg',
              name: 'Fran Mendez',
              job_title: 'Founder of AsyncAPI Initiative',
              comapny: 'AsyncAPI Initiative',
              position: 'Founder',
              media_card: 'fran-smc.jpg',
              about: [
                'Fran is the founder of the AsyncAPI Initiative. He’s a software engineer with a strong focus on event-driven APIs and microservices. In his spare time, he enjoys playing beach volleyball, kayaking, and stand-up paddle surf.'
              ],
              topic_title: 'AsyncAPI & The Future of API Specs',
              topic_description: [
                'In this session, Fran will walk you through his vision and predictions for the future of the API specifications. As the creator of the AsyncAPI Initiative and a long-term user of OpenAPI and JSON Schema, he\'ll provide insights on how the future API specs landscape will look like and how the different specs are meant to play together for the benefit of the user.'
              ],
              linkedin: 'fmvilas',
              twitter: 'fmvilas'
            },
            {
              data_target: 'paulModal',
              image_url: 'paul-pro.jpg',
              name: 'Paul Taylor',
              job_title: 'Strategic Advisor Director at MuleSoft',
              comapny: 'MuleSoft',
              position: 'Strategic Advisor Director',
              media_card: 'paul-smc.jpg',
              about: [
                'Paul is an architect with a long career guiding enterprise customer on integration best practice, he clearly remembers when RPC with hand crafted payloads was the height of sophistication way before SOA got all formal and he means RPC not gRPC!',
                'Paul is based in Sydney, Australia and has the pleasure of working for MuleSoft in the JAPAC Customer Success Strategy and Architecture team.'
              ],
              topic_title: 'Getting Started with AsyncAPI',
              topic_description: [
                'An introduction to AsyncAPI and its use in building event driven architecture. A look at both the specification and demo of building an asynchronous API definition.'
              ],
              linkedin: 'paultay'
            },
            {
              data_target: 'annaModal',
              image_url: 'anna-pro.jpg',
              name: 'Anna Tsolakou',
              job_title: 'Developer Advocate at Amadeus',
              comapny: 'Amadeus',
              position: 'Developer Advocate',
              media_card: 'anna-smc.jpg',
              about: [
                'Anna is a software engineer and Developer Advocate at Amadeus. Passionate about open-source and AI, she loves trying different roles so DevRel is the ideal mix of software and people.'
              ],
              topic_title: 'Why You Need a DevRel Team for Your API',
              topic_description: [
                'How do you build trust with your users? How do you collect feedback and ensure it is passed smoothly to your development teams? How do you ensure your users have the right tools and support to build successful applications? To meet the needs of demanding developers, having a well-designed API product is not enough. You must also provide community, documentation, tutorials and tools – in short, you need a Developer Relations (DevRel) team. In this talk, we’ll discuss why a DevRel team is necessary to provide a public face for your APIs and how DevRel can nurture a long-term user community that is key to any successful API program.'
              ],
              linkedin: 'tsolakouanna',
              twitter: 'AnnaTsolakou'
            },
            {
              data_target: 'ilonaModal',
              image_url: 'ilona-pro.jpg',
              name: 'Ilona Koren-Deutsch',
              job_title: 'Technical Documentation Manager at Twitch',
              comapny: 'Twitch',
              position: 'Technical Documentation Manager',
              media_card: 'ilona-smc.jpg',
              about: [
                'Ilona is Technical Documentation Manager and part of Twitch\'s Developer Relations team. She enables people from all backgrounds and disciplines to be more effective through documentation that is crystal clear, actionable, and inclusive. A University of Pennsylvania graduate, she has pioneered and driven the highest standards in technical documentation quality during her 20+ year career. She is involved with API Days Women in APIs initiative as a mentor and a leader of our new "4 Steps to Speaking" program. Through her volunteer work, she is Communications Lead on the TechWomen Alumnae Council, and is actively engaged in encouraging girls to pursue STEM careers and supporting women in the tech space.'
              ],
              topic_title: 'The Three Things Your Developer Portal Can\'t Live Without',
              topic_description: [
                'There are three things your developer portal needs in order to be a success. This talk will define "success" and then share what these things are. There will be time for questions, comments, and sharing at the end.'
              ],
              linkedin: 'ilonakd'
            },
            {
              data_target: 'maciejModal',
              image_url: 'maciej-pro.jpg',
              name: 'Maciej Treder',
              job_title: 'Senior Software Development Engineer at Akamai Technologies',
              comapny: 'Akamai Technologies',
              position: 'Senior Software Development Engineer',
              media_card: 'maciej-smc.jpg',
              about: [
                'Enthusiast of web technologies especially Single Page Apps, Progressive Web Apps, microservices, and Internet of Things. Author of articles about JavaScript, NodeJS, and Angular. Open Source contributor - creator of @ng-toolkit opensource project - set of tools for expanding existing Angular applications.'
              ],
              topic_title: 'WT* is JWT',
              topic_description: [
                'JWT, JWKS, JOSE header etc. etc. etc. Do you feel lost with those shortcuts? This session explains in a simple way what is JSON Web Token and how you can utilize them in your daily job.'
              ],
              linkedin: 'maciej-treder',
              twitter: 'MaciejTreder'
            },
            {
              data_target: 'developersteveModal',
              image_url: 'developersteve-pro.jpg',
              name: 'DeveloperSteve Coochin',
              job_title: 'Developer Advocate at IBM Australia',
              comapny: 'IBM Australia',
              position: 'Developer Advocate',
              media_card: 'developersteve-smc.jpg',
              about: [
                'DeveloperSteve comes from a strong background as a developer, with over 20 years industry experience he has worked with companies, startups and not for-profits of all sizes.',
                'DeveloperSteve has worked closely with developer communities as a Tech Evangelist and Advocate to develop and nurture the healthy developer and start-up culture that continues to flourish. With a passion for sharing knowledge DeveloperSteve is an established international speaker and loves geeking out with people everywhere.',
                'As a Tech Evangelist and Advocate he has represented and worked with global companies such as IBM, PayPal, Braintree, Xero, Telstra, Nginx, Gitlab and more.'
              ],
              topic_title: 'Solving Challenges in API Testing to Achieve More Agile Development',
              topic_description: [
                'APIs are the central nervous system that binds your core platform to your mobile app, website, and the rest of the world. As enterprises are continuously expanding their digital footprint, they must ensure the API behavior is intact, as it has a far-reaching effect on the app’s execution and end-user experience. In this automated world of DevOps and agile development, the first step towards achieving high quality is to start testing sooner in the development lifecycle with a “Shift Left” strategy.',
                'In this session, we will talk about how enterprises can balance the long-running act between moving at the speed without compromising the code quality.'
              ]
            },
            {
              data_target: 'atsushiModal',
              image_url: 'atsushi-pro.jpg',
              name: 'Atsushi Nakatsugawa',
              job_title: 'CEO of MOONGIFT',
              comapny: 'MOONGIFT',
              position: 'CEO',
              media_card: 'atsushi-smc.jpg',
              about: [
                'Atsushi is CEO of MOONGIFT Inc. in Japan. He is Ruby and JavaScript/Node.js Programmer. Atsushi manages Website named MOONGIFT that introduce Open source Software everyday to Japanese programmers from 2004. It is the biggest Open source information site in Japan. He started DevRel agency since 2015, and has a knowledge of how to create content for programmers. He also has several communities involved developer relations in APAC such as DevRel Meetup in Tokyo, Singapore, Bangalore and Taipei. He is mainly organizer of DevRelCon Tokyo since 2017 and DevRel/Asia 2020, co-organizerd DevRelCon Earth 2020 and DevRel/Japan 2019.'
              ],
              topic_title: 'Let\'s Get Started Development of API Client Library',
              topic_description: [
                'Atsushi develops a number of client libraries for APIs within DevRel that a marketing measure for developers. The languages we use are diverse, and we develop in JS/TS/Go/Dart/Python/PHP/Ruby/Java/Rust/Swift/Swift/Kotlin/VBA/Google Apps Script/Deno. Although these libraries are unofficial, they are used by many developers, and the presence of API libraries is what differentiates us from other services. As an individual developer, there are benefits to being exposed to new technologies, getting feedback on developing in open source, and having your name on the library management service. In this talk, I will share those personal experiences and some things to keep in mind when developing an API library.'
              ],
              linkedin: 'goofmint'
            }
          ]
        },
        {
          group: 'WorkshopTrack',
          title: 'Workshop Track',
          speaker: [
            {
              data_target: 'nickyModal',
              image_url: 'nicky-pro.jpg',
              name: 'Nicky Choo',
              job_title: 'Head - Application, Integration and Management Platform Business of IBM APAC',
              comapny: 'IBM APAC',
              position: 'Head - Application, Integration and Management Platform Business',
              media_card: 'nicky-smc.jpg',
              about: [
                'Nicky Choo is currently responsible for Application, Management and Integration Business for Asia Pacific. This includes all application platforms, DevOps, Blockchain, API, Application Integration, Messaging, Management Platforms and Edge. Nicky has over 25 years of experience in the technology industry having spent time leading development teams in UBS and BP and have also held sales leadership positions in Oracle, BEA, Extreme Networks, Solace and CenturyLink.'
              ],
              topic_title: 'The Evolution of API Drive Integration',
              topic_description: [
                'Service-enabling your internal and external corporate assets, without re-writing an application, allows for a fast and effective way to react to new business demands. In order to realize the promise of the new enterprise, it is also mandatory to effectively manage, orchestrate and operate your services. In this session, the subject-matter-expert, Nicky Choo from IBM APAC, will present strategies and examples to get the most out of your assets, and outline the steps you can take to build a solid foundation for hybrid integration, APIs and event driven solutions down the road.'
              ],
              linkedin: 'nickychoo'
            },
            {
              data_target: 'samuelModal',
              image_url: 'samuel-pro.jpg',
              name: 'Samuel Leung',
              job_title: 'Reginall Solutions Architect at NGINX (Part of F5)',
              comapny: 'NGINX (Part of F5)',
              position: 'Reginall Solutions Architect',
              media_card: 'samuel-smc.jpg',
              about: [
                'Samuel is the Regional Solutions Architect at Nginx, part of F5, based in Hong Kong. He is a senior technical manager who has deep understanding in solutions consulting and professional services, especially in cloud and security domains. Prior to Nginx, he has gained an extensive experience working as a cloud solutions architect in companies like Tencent, IBM and Microsoft.'
              ],
              topic_title: 'Protecting API Workloads Against OWASP Top 10 and Programming Language CVE\'s'
            },
            {
              data_target: 'rajeshModal',
              image_url: 'rajesh-pro.jpg',
              name: 'Rajesh Bavanantham',
              job_title: 'Domain Architect at NGINX (Part of F5)',
              comapny: 'NGINX (Part of F5)',
              position: 'Domain Architect',
              media_card: 'rajesh-smc.jpg',
              topic_title: 'Protecting API Workloads Against OWASP Top 10 and Programming Language CVE\'s'
            },
            {
              data_target: 'forestModal',
              image_url: 'forest-pro.jpg',
              name: 'Forest Jing',
              job_title: 'DAOPS Foundation China Director at DevOps',
              comapny: 'DevOps',
              position: 'DAOPS Foundation China Director',
              media_card: 'forest-smc.jpg',
              about: [
                'Forest Jing is a DevOps practitioner and leader in China who is the co-founder of DevOps Times Community. He is a Jenkins Ambassador and DevOps Institute Ambassador. He and his team have won the Most Valuable Advocate of the Jenkins community.'
              ],
              topic_title: 'Build your APIs and DevOps Hybrid Skills',
              topic_description: [
                'DevOps is changing the way we develop and operate software. We introduce Agile, Continuous Delivery, SRE, DevSecOps to improve our software engineering capability. DevOps also supports the digital transformation of enterprises all over the world. What enterprises need right now is DevOps humans.This topic will help you to understand the most important knowledge to practice DevOps in your own team and company.'
              ]
            },
            {
              data_target: 'johnModal',
              image_url: 'john-pro.jpg',
              name: 'John Lim',
              job_title: 'Solutions Engineer at Auth0',
              comapny: 'Auth0',
              position: 'Solutions Engineer',
              media_card: 'john-smc.jpg',
              about: [
                'John is a software developer who is passionate about his craft. As an Agile advocate, he mentors teams in Agile practices while continuously practising and honing his craft. His efforts have seen teams becoming more efficient through a continual improvement process.',
                'In his spare time, John seeks to contribute to Open Source(https://github.com/johnlim) and the software community in any small ways he can. He has organised the Agile Singapore Conferences and currently hosts the Singapore Identity & Security Meetup (https://www.meetup.com/Singapore-Identity-and-Security-Meetup/).'
              ],
              topic_title: 'Access Control for your API Endpoints with Auth0',
              topic_description: [
                'During our Live Coding Workshop you\'ll learn how to integrate Auth0 with your web and mobile apps within minutes and Use the core Authorisation features of Auth0 to allow role-based access control (RBAC) of your APIs.'
              ],
              linkedin: 'johnlimdingwei'
            }
          ]
        }
      ],
      sponsor_list: [
        {
          type: 'GOLD SPONSORS',
          css_class: '',
          sponsor: [
            {
              css_class: '',
              image_css: 'max-w-27rem',
              image_url: 'ibm.png'
            }
          ]
        },
        {
          type: 'SILVER SPONSORS',
          css_class: 'w-100 w-md-60 w-lg-50 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-6 mb-1 mb-sm-0',
              image_css: 'max-w-19rem',
              image_url: 'auth0.png'
            },
            {
              css_class: 'col-sm-6',
              image_css: 'max-w-19rem',
              image_url: 'nginx.png'
            }
          ]
        },
        {
          type: 'BRONZE & COMMUNITY',
          css_class: 'w-100 w-md-60 w-lg-50 mx-auto',
          sponsor: [
            {
              css_class: 'col-sm-6 col-lg-4 mb-3 mb-lg-4',
              image_css: 'max-w-15rem',
              image_url: 'alibaba-cloud.png'
            },
            {
              css_class: 'col-sm-6 col-lg-4 mb-3 mb-lg-4',
              image_css: 'max-w-15rem',
              image_url: 'forgerock.png'
            },
            {
              css_class: 'col-sm-6 col-lg-4 mb-3 mb-lg-4',
              image_css: 'max-w-15rem',
              image_url: 'daops.png'
            },
            {
              css_class: 'col-sm-6 col-lg-6 mb-3 mb-lg-0',
              image_css: 'max-w-15rem',
              image_url: 'wso2.png'
            },
            {
              css_class: 'col-sm-6 col-lg-6 mb-3 mb-lg-0',
              image_css: 'max-w-15rem',
              image_url: 'wia.png'
            }
          ]
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    $('.js-go-to').each(function() {
      new HSGoTo($(this)).init()
    })
    // this.loadScriptRecapSubscribeForm('https://js.hsforms.net/forms/v2.js', () => {
    //   const secondScript = document.createElement('script')
    //   secondScript.innerHTML = `
    //     hbspt.forms.create({
    //       portalId: "7091000",
    //       formId: "4daac438-a701-4a30-89de-fefdd7a7306f"
    //     });
    //   `
    //   this.$refs.recapSubscribeForm.appendChild(secondScript)
    // })
    // window.addEventListener('message', event => {
    //   if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
    //     this.isSubmitted = true
    //   }
    // })
    $('.js-fancybox').each(function() {
      $.HSCore.components.HSFancyBox.init($(this))
    })
  },
  // methods: {
  //   loadScriptRecapSubscribeForm(url, callback) {
  //     var script = document.createElement('script')
  //     script.type = 'text/javascript'
  //     script.src = url
  //     script.onreadystatechange = callback
  //     script.onload = callback
  //     this.$refs.recapSubscribeForm.appendChild(script)
  //   }
  // },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Apidays Live Hong Kong 2020 | %s',
      link: [
        { rel: 'canonical', href: 'https://hub.openapihub.com/community-updates/20201008-Apidays-Live-Hong-Kong-2020' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Apidays Live Hong Kong | beNovelty' },
        { property: 'og:description', content: 'Join us at Apidays Hong Kong 2020 – it is the first-ever API event in Hong Kong connecting regional domain leaders in exchanging thought leadership in 3 perspectives – from Digital Transformation, API Technology to Developer Community.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/apidays-live-hongkong.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/events/apidays-live-hongkong' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../../assets/vendor/slick-carousel/slick/slick.css");
@import url("../../../assets/vendor/fancybox/dist/jquery.fancybox.min.css");
</style>
